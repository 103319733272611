import React, { useEffect, useState } from 'react';
import PlayList from './playlist';
import { imageToBase64 } from "../../util";
import { RefrashToken, UpdatePlayList, UpdateImage, GetPlayList } from "../../api/Api"
import { api } from "../../api/net";
import { ShowLoader, HiddenLoader } from '../../loader/loader';

function EditUser() {
    const [playlist, setPlayList] = useState({})
    const [allPlaylist, setAllPlaylist] = useState([])
    const [refrashToken, setToken] = useState('')
    const [id, setId] = useState(0)

    useEffect(() => {
        ShowLoader()

        var params = JSON.parse(window.localStorage.getItem("playlist_edit"));

        if (!params) window.location.href = '/Gerenciar';

        var current_playlist = params.p;
        var token = atob(params.r);
        var user_id = params.u;
        var all_playlist = params.ap;
        debugger
        setPlayList(current_playlist);
        setToken(token);
        setId(user_id);
        setAllPlaylist(all_playlist)

        HiddenLoader()
    }, [])

    const Atualizar = async (novoForm) => {
        ShowLoader()
        window.localStorage.setItem("refresh_token", refrashToken)

        const atual_playlist = { name: playlist.name, description: playlist.description, image: playlist.images[0].url };
        const nova_playlist = novoForm;

        const { access_token } = await RefrashToken();
        await UpdatePlayList({ id: playlist.id }, {
            name: nova_playlist.name,
            description: nova_playlist.description
        }, access_token)

        playlist.name = nova_playlist.name;
        playlist.description = nova_playlist.description;

        if (atual_playlist.image != nova_playlist.image) {

            imageToBase64(nova_playlist.image).then(async x => {
                await UpdateImage(playlist, x.split(',')[1]);
            });
            const { access_token } = await RefrashToken();
            await GetPlayList(access_token);

            alert('Atualizando a imagem...');

            var _img = "";
            do {
                const { access_token } = await RefrashToken();
                await GetPlayList(access_token);
                var str = window.localStorage.getItem("playlists")
                var obj = JSON.parse(str);
                obj.items.forEach(all_playlist_spotify => {
                    if (all_playlist_spotify.id === playlist.id) {
                        _img = all_playlist_spotify.images[0].url
                    }
                });

            } while (_img === atual_playlist.image)

            const novaImg = {
                "height": playlist.images[0].height,
                "url": _img,
                "width": playlist.images[0].width,
            }

            playlist.images = [];
            playlist.images.push(novaImg);
        }

        AtualizaPlayListObj(playlist);
        api.put("/usuario/atualiza/playlist", { novaplaylist: JSON.stringify(allPlaylist), id }, res => {
            if (res.data.atualizado) {
                alert('Atualização feita com sucesso!');
            } else {
                alert('Atualização não realizada!');
            }
        }, err => {
            alert('Atualização não realizada!');
        })
        HiddenLoader()
    }

    const AtualizaPlayListObj = (nova_playlist) => {
        allPlaylist.items.forEach(playlist => {
            if (playlist.id === nova_playlist.id) {

                playlist.name = nova_playlist.name;
                playlist.description = nova_playlist.description;
                playlist.images[0].url = nova_playlist.images[0].url;
            }
        });
    }

    return (
        <div className='container'>
            <PlayList Atualizar={Atualizar} />
        </div>
    );
}

export default EditUser;