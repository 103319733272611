import React from 'react';
import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill, BsFillBellFill }
from 'react-icons/bs'
import { TbPlaylist } from "react-icons/tb";
import { AiOutlineRollback } from "react-icons/ai";
export default (props) => {

    const {
        countUser,
        countPlaylist,
        countRollback
    } = props;

    return (
        <div className='main-cards'>
            <div className='card'>
                <div className='card-inner'>
                    <h3>TOTAL USUÁRIOS</h3>
                    <BsPeopleFill color='#fff' className='card_icon' />
                </div>
                <h1>{countUser ? countUser : 0}</h1>
            </div>
            <div className='card'>
                <div className='card-inner'>
                    <h3>TOTAL PLAYLISTS</h3>
                    <TbPlaylist color='#fff' className='card_icon' />
                </div>
                <h1>{countPlaylist ? countPlaylist : 0}</h1>
            </div>
            <div className='card'>
                <div className='card-inner'>
                    <h3>TOTAL ROLLBACKS</h3>
                    <AiOutlineRollback color='#fff' className='card_icon' />
                </div>
                <h1>{countRollback ? countRollback : 0}</h1>
            </div>
        </div>
    );
}