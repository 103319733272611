import React, { useState, useEffect } from 'react';
import { AiOutlineCloseCircle, AiOutlineMenuFold } from 'react-icons/ai';
import logo from '../../images/Arquivos_Landing_Page/logo.svg'
import { RxExit } from 'react-icons/rx';
import { AiOutlineMenu, AiOutlineLogout } from 'react-icons/ai';
import { useRef } from 'react';
import LinkCadastroModal from '../modal/linkCadastroModal';
import Dropdown from 'react-bootstrap/Dropdown';
import { api } from '../api/net';
import ChangePasswordModal from '../modal/changePassword';
import NewUserModal from '../modal/newUser';

function Layout({ children }) {
    const [showMenu, setShowMenu] = useState(false);
    const [adm, setAdm] = useState(false);
    const modalLink = useRef();
    const [modalFirstAccess, setFirstAccess] = useState(true);
    const [_timer, setTimer] = useState(window.localStorage.getItem("_timer") ? parseInt(window.localStorage.getItem("_timer")) : 0)

    useEffect(() => {
        const timer = setInterval(() => {
            if (_timer > 0) {
                setTimer(_timer - 1);
                window.localStorage.setItem("_timer", `${_timer - 1}`)
            }
        }, 1000);

        if (modalFirstAccess) {
            const jwt = window.localStorage.getItem("x-access-token");
            if (!jwt) window.location.href = "/";
            const data_user = JSON.parse(atob(jwt.split('.')[1]));
            setAdm(data_user.Adm === 1)
            setFirstAccess(data_user.PrimeiroAcesso === 1);
        }

        return () => clearInterval(timer);
    }, [_timer]);

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };


    const AbrirModal = () => {
        if (window.confirm("Deseja cadastrar um novo usuário?")) {
            modalLink.current.Exibir();
        }
    }

    const Logout = () => {
        window.localStorage.removeItem("x-access-token");
        window.localStorage.removeItem("_timer");
        window.location.href = "/";
    }

    const submit = (oldPsw, newPsw, confirmPsw) => {

        if (newPsw != confirmPsw) {
            alert('As senhas não se coincidem')
            return;
        }

        api.put("usuario/alterarSenha", { senha_antiga: oldPsw, nova_senha: newPsw }, res => {
            if (res.data.alterado) {
                alert('Senha alterada com sucesso!');
                alert('Necessário fazer login novamente!');
                Logout();
            } else {
                alert('Senha atual incorreta!');
            }
        }, err => {

        })
    }

    return (
        <section>
            <ChangePasswordModal submit={submit} show={modalFirstAccess} />
            <LinkCadastroModal ref={modalLink} />
            <div className='header_home'>
                <div className="container" style={{ width: '40%' }}>
                    <div className="container_img_home">
                        <img onClick={() => window.location.href = 'dashboard'} src={logo} alt="" />
                    </div>
                </div>
                <div className="container">
                    <div className='container_menu_home'>
                        <AiOutlineMenu size={25} onClick={() => setShowMenu(true)} />
                    </div>
                    <div className="container_links_home" style={{ justifyContent: 'space-between' }}>
                        <a href="Dashboard">Dashboard</a>
                        {adm && <Dropdown>
                            <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                                Usuários
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <NewUserModal />
                                {/* <Dropdown.Item href="#/action-2">Editar</Dropdown.Item> */}
                                {/* <Dropdown.Item href="#/action-3">Excluir</Dropdown.Item> */}
                            </Dropdown.Menu>
                        </Dropdown>}
                        <Dropdown>
                            <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                                PlayLists
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={AbrirModal}>Cadastrar Usuário</Dropdown.Item>
                                <Dropdown.Item href="/Gerenciar">Gerenciar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <span>Sessão expira em {formatTime(_timer)}</span>
                        <a onClick={Logout} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><AiOutlineLogout />Logout</a>
                    </div>
                    <div className={`menu_home ${showMenu ? 'menuactive' : ''}`} >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className='container_menu_active_home'>
                                <RxExit size={40} color='#000' onClick={() => setShowMenu(false)} />
                            </div>
                            <hr />
                            <div className="container_links_menu_home_mobile">
                                <a href="Dashboard">Dashboard</a>
                                {adm && <Dropdown>
                                    <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                                        Usuários
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <NewUserModal />
                                        {/* <Dropdown.Item href="#/action-2">Editar</Dropdown.Item> */}
                                        {/* <Dropdown.Item href="#/action-3">Excluir</Dropdown.Item> */}
                                    </Dropdown.Menu>
                                </Dropdown>}
                                <Dropdown>
                                    <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                                        PlayLists
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={AbrirModal}>Cadastrar Usuário</Dropdown.Item>
                                        <Dropdown.Item href="/Gerenciar">Gerenciar</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <span>Sessão expira em {formatTime(_timer)}</span>
                                <a onClick={Logout} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><AiOutlineLogout />Logout</a>
                            </div>
                            <hr />
                            <div style={{ padding: 10 }}>
                                <img src={logo} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <br />
            <br />
            <br />
            <div class="height-100 bh-light" >
                {children}
            </div>
        </section>
    );
}

export default Layout;