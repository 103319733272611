import "./loader.css";
import logo from '../../images/Loader.png'

export const Loader = () => {
    return (
        <div className="loader hidden" id="loader"> 
            <div>
                <img src={logo} />
            </div>
        </div>
    )
}

export const ShowLoader = () => {
    document.getElementById("loader").classList.remove("hidden");
}

export const Active = () => {
    return document.getElementById("loader").classList.contains("hidden");
}

export const HiddenLoader = () => {
    document.getElementById("loader").classList.add("hidden");
}