import React from 'react'
import { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { BiSolidPlaylist } from 'react-icons/bi'

export default class LinkCadastroModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            playlists: [],
            refrash_token: '',
            userId: ''
        }

        this.Exibir = (playlists, refrash_token, userId) => {
            this.setState({ show: true, playlists, refrash_token, userId })
        }
    }

    handleModal() {
        this.setState({ show: !this.state.show })
    }

    render() {
        return (<>
            <Modal show={this.state.show}>
                <Modal.Header>
                    Selecione a playlist
                </Modal.Header>

                <Modal.Body>
                    {
                        this.state.playlists.map(playlist => {
                            return (
                                <>
                                    <button style={{ width: '100%' }} type="button" onClick={() => {
                                        window.localStorage.setItem("playlist_edit",
                                            JSON.stringify({
                                                p: playlist,
                                                r: btoa(this.state.refrash_token),
                                                u: this.state.userId,
                                                ap: this.state.playlists
                                            })); window.location.href = '/EditUser'
                                    }} class="btn btn-light"><BiSolidPlaylist size={25} />{playlist.name}</button >
                                    <br />
                                    <br />
                                </>
                            )
                        })
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => this.handleModal()}>Fechar</Button>
                </Modal.Footer>

            </Modal >
        </>);
    }
}