import { CLIENT_ID, CLIENT_SECRET, EDPOINTS } from './config';

export const GetTokenUser = async () => {
    var code = window.localStorage.getItem("code");
    var authOptions = {
        url: 'https://accounts.spotify.com/api/token',
        body: new URLSearchParams(Object.entries({
            grant_type: 'authorization_code',
            code,
            redirect_uri: 'https://myplaylist.online/Agradecimento'
        })).toString(),
        method: "POST",
        headers: {
            Authorization: `Basic ${btoa(CLIENT_ID + ':' + CLIENT_SECRET)}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    const response = await fetch(EDPOINTS.API_TOKEN, authOptions);
    return await response.json();
}

export const RefrashToken = async () => {
    const payload = {
        method: 'POST',
        headers: {
            Authorization: `Basic ${btoa(CLIENT_ID + ':' + CLIENT_SECRET)}`,
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            grant_type: 'refresh_token',
            refresh_token: window.localStorage.getItem("refresh_token"),
            client_id: CLIENT_ID
        }),
    }
    const response = await fetch(EDPOINTS.API_TOKEN, payload);
    const data = await response.json();
    window.localStorage.setItem("access_token", data.access_token);

    return data;
}

export const GetUser = async (token) => {
    var param = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    };

    const response = await fetch(EDPOINTS.GET_USER, param);

    if (response.status == 200) {
        const user = await response.json();
        window.localStorage.setItem("user", JSON.stringify(user))
    } else {
        const { access_token } = await RefrashToken();
        GetUser(access_token);
    }
}

export const GetPlayList = async (token) => {
    await GetUser(token);
    var param = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
    };
    
    var user = JSON.parse(window.localStorage.getItem("user"));

    var response = await fetch(`${EDPOINTS.PLAYLISTS}${user.id}/playlists?offset=0&limit=50`, param)

    if (response.status == 200) {
        var data = await response.json();
        window.localStorage.setItem("playlists", JSON.stringify(data));
    }
    else if (response.status == 429) {
        alert('Quantidade maxima de request na api do spotify atingida.')
    }
    else {
        alert('Erro ao buscar as playlists.')
    }
}

export const GetAuth = () => {
    const uri = `${EDPOINTS.AUTHORIZEURL}?response_type=code&client_id=${CLIENT_ID}&scope=ugc-image-upload user-read-private playlist-read-collaborative playlist-modify-public playlist-read-private playlist-modify-private&redirect_uri=${'https://myplaylist.online/Agradecimento'}`
    window.location.href = uri;
}

export const UpdatePlayList = async (playlist, playlist_bd, token) => {
    var authOptions = {
        body: JSON.stringify({
            "name": playlist_bd.name,
            "description": playlist_bd.description,
            "public": true
        })
        ,
        method: "PUT",
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    };

    const response = await fetch(EDPOINTS.UPDATE_PLAY_LIST + playlist.id, authOptions);
    return response.status == 200;
}

export const UpdateImage = async (playlist, image) => {
    const { access_token } = await RefrashToken();
    var authOptions = {
        body: image,
        method: "PUT",
        headers: {
            'Content-Type': 'image/jpeg',
            Authorization: `Bearer ${access_token}`
        }
    };
    let url = EDPOINTS.UPDATE_PLAY_LIST + playlist.id + "/images";
    const response = await fetch(url, authOptions);

    if (response.status !== 202) {
        UpdateImage(playlist, image);
    }

}