import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css';
import { router } from './components/router/router';
import {
  RouterProvider,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Loader } from './components/loader/loader';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Loader />
    <RouterProvider router={router} />
  </>
);
