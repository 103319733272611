export async function imageToBase64(url) {
  try {
    // Faz uma solicitação GET para a URL da imagem
    const response = await fetch(url);

    // Verifica se a solicitação foi bem-sucedida
    if (response.ok) {
      // Converte a resposta em dados em formato de array de bytes
      const blob = await response.blob();

      // Lê os dados da imagem como um array de bytes
      const reader = new FileReader();
      reader.readAsDataURL(blob);

      // Aguarda o término da leitura
      return new Promise((resolve) => {
        reader.onloadend = () => {
          // A resposta será uma string base64 da imagem
          resolve(reader.result);
        };
      });
    } else {
      throw new Error('Falha ao buscar a imagem');
    }
  } catch (error) {
    console.error('Erro: ', error);
  }
}