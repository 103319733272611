import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: false,
            text: 'Rollbacks por playlist',
        },
    },
};

const gerarCorAleatoria = () => {
    const letrasHex = "0123456789ABCDEF";
    let cor = "#";

    // Gera seis caracteres hexadecimais aleatórios
    for (let i = 0; i < 6; i++) {
        cor += letrasHex[Math.floor(Math.random() * 16)];
    }

    return cor;
}

function BarChart(props) {
    const obj = [];
    const copyArray = props.data;
    copyArray.forEach((element, i) => {
        const exists = obj.filter(c => c.name == element.name).length > 0
        if (exists) {
            const current = copyArray[i].count;
            obj.filter(c => c.name == element.name)[0].count += current
        } else {
            obj.push({
                name: element.name,
                count: element.count,
            });
        }

    });
    let labels = obj.map(user=> user.name);
    const data = {
        labels,
        datasets: [
            {
                label: 'Total de Rollback',
                data: obj.map(user => user.count),
                backgroundColor: '#6CBD45',
            },
        ],
    }

    return <Bar options={options} data={data} />;
}

export default BarChart