import React, { useEffect, useState } from 'react';
import './home.css'
import logo from '../../../images/Arquivos_Landing_Page/logo.svg'
import { AiOutlineMenu } from 'react-icons/ai';
import { RxExit } from 'react-icons/rx';
import Carousel from 'react-bootstrap/Carousel';
import { Container } from 'react-bootstrap';

import { Layout } from '../../layout/layout_page';

import img from '../../../images/Arquivos_Landing_Page/Capturar.PNG';

import phone from '../../../images/Arquivos_Landing_Page/smart-phone.png'
import robo from '../../../images/Arquivos_Landing_Page/android.png'
import destaque1 from '../../../images/Arquivos_Landing_Page/destaque_1.png';
import destaque2 from '../../../images/Arquivos_Landing_Page/destaque_2.png';
import destaque3 from '../../../images/Arquivos_Landing_Page/destaque_3.png';
import destaque4 from '../../../images/Arquivos_Landing_Page/destaque_4.png';
import destaque5 from '../../../images/Arquivos_Landing_Page/destaque_5.png';
import destaque6 from '../../../images/Arquivos_Landing_Page/destaque_6.png';
import banner from '../../../images/Arquivos_Landing_Page/banner_instagram.png'
import banner2 from '../../../images/Banner-2.jpg'
import banner3 from '../../../images/Banner-3.jpg'


const Home = () => {
    const [showMenu, setShowMenu] = useState(false);

    useEffect(()=> {
        var params = new URLSearchParams(document.location.search)
        var scrollApp = params.get("app");
    
        if(scrollApp)
        {
            document.getElementById("app").scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            });
        }
    },[])

    return (
        <Layout>
            <section style={{ width: '100vw', height: 'auto' }}>
                <Container>
                    <Carousel style={{ width: '100%' }}>
                        <Carousel.Item>
                            <img src={img} className='carousel_img' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={banner2} className='carousel_img' />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={banner3} className='carousel_img' />
                        </Carousel.Item>
                    </Carousel>
                </Container>
            </section>
            <section style={{ width: '100vw', height: 'auto' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                    <div>
                        <img src={phone} id="app" style={{ width: '100%', height: 'auto' }} alt="" />
                    </div>
                    <div>
                        <h1>Proteja suas<br />Playlists no Spotify</h1>
                        <br />
                        <span>
                            <p>Nosso aplicativo foi projetado com um<br />
                                compromisso inabalável de proteger suas<br />
                                preciosas playlists do Spotify contra<br />
                                possível ataques</p>
                        </span>
                        <button onClick={() => window.location.href = '/CriarConta'} className='button_create_accont'>CRIAR CONTA</button>
                    </div>
                </div>
            </section>
            <section style={{ width: '100vw', height: 'auto' }}>
                <div className='robo_container_home'>
                    <div className="container">
                        <div className='robo_container_home' style={{ background: 'none' }}>
                            <div className='center_robo_text_home'>
                                <span style={{ color: '#fff', fontSize: 25,fontWeight:'bold' }}>Inteligência<br />Artificial Online</span>
                                <br />
                                <span style={{ color: '#fff', fontSize: 20 }}>
                                    Nossa inteligência artificial protege sua<br />playlist contra ataques maliciosos e mantém<br />um relatório atualizado diariamente.
                                </span>
                            </div>
                            <div className='robot_img'>
                                <img src={robo} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />
            <section style={{ width: '100vw', height: 'auto' }}>
                <br />
                <h1 style={{ textAlign: 'center' }}>Benefícios do App</h1>
                <br />
                <br />
                <div className='container'>
                    <div className="row">
                        <div className="col-md-3" />
                        <div className="col-md-2">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <img src={destaque2} style={{ width: '50px' }} alt="" />
                                <span>Funcionamento<br />24 Horas por dia</span>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <img src={destaque5} style={{ width: '50px' }} alt="" />
                                <span>Segurança via API</span>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <img src={destaque4} style={{ width: '50px' }} alt="" />
                                <span>Proteção da<br />Capa da Playlist</span>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="row">
                        <div className="col-md-3" />
                        <div className="col-md-2">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <img src={destaque6} style={{ width: '50px' }} alt="" />
                                <span>Dashboard de <br />ações executadas</span>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <img src={destaque3} style={{ width: '50px' }} alt="" />
                                <span>Proteção de<br /> Título e Descrição</span>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <img src={destaque1} style={{ width: '50px' }} alt="" />
                                <span>Atualização<br />a cada 5 Minutos</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br />
            <br />
            <section style={{ width: '100vw', height: 'auto' }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <a href="https://www.instagram.com/myplaylistonline" target='_blank' className='container_kid_home'>
                        <img style={{ width: '100%', height: 'auto' }} src={banner} alt="" />
                    </a>
                </div>
            </section>
        </Layout>
    );
}

export { Home };