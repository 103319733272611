import React from 'react';
import { BarChart, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line }
    from 'recharts';
import './dash.css'

import ChartBar from './Bar';
import ChartLine from './Line';

export default props => {

    const {
        data
        , setInitialDate
        , setEndDate
        , GetByFilter
    } = props;

    return (
        <div className='charts'>
            <form className="filter" onSubmit={e => { e.preventDefault(); GetByFilter() }}>
                <label style={{ color: '#000', marginRight: 5 }} htmlFor="">De</label>
                <input required onChange={e => setInitialDate(e.target.value)} type="date" className='form-control' name="" id="" />
                <label style={{ color: '#000', marginRight: 5, marginLeft: 5 }} htmlFor="">até</label>
                <input required onChange={e => setEndDate(e.target.value)} type="date" className='form-control' name="" id="" />
                <button style={{ marginRight: 5, marginLeft: 5, padding: 6 }} className='button_create_accont'>Buscar</button>
            </form>

            <ResponsiveContainer width="100%" height="100%">
                <ChartBar data={data} />
            </ResponsiveContainer>

            <ResponsiveContainer width="100%" height="100%">
                <ChartLine data={data} />
            </ResponsiveContainer>
        </div>
    );
}