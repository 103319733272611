import { Layout } from '../../layout/layout_page';
import React, { useState } from 'react';
import './createAccount.css'
import phone from '../../../images/Arquivos_Landing_Page/smart-phone.png'
import robo from '../../../images/Arquivos_Landing_Page/android.png'
import destaque5 from '../../../images/Arquivos_Landing_Page/destaque_5.png';
import destaque2 from '../../../images/Arquivos_Landing_Page/destaque_2.png';
import { api } from '../../api/net';
import PhoneInput from '../../inputs/Phone';

function CreateAccount() {
    const [success, setSuccess] = useState(false);

    const [form, setForm] = useState(
        {
            name: '',
            email: '',
            tel: '',
            whatsapp: '',
            aboutourservices: '',
            countplaylists: ''
        }
    )

    const changeText = e => setForm({ ...form, [e.target.name]: e.target.value })

    const submit = (e) => {
        api.post('/solicitacao', form, res => {
            debugger
            setSuccess(true);
        })
    }

    return (
        <Layout>
            <div className='background_create_account'>
                <h3>Crie Sua Conta</h3>
            </div>
            <section style={{ width: '100vw', height: 'auto',overflowX:'hidden' }}>
                <div className='container_register' >
                    <div>
                        <div className="smartphone" >
                            <img src={phone} style={{ width: '100%', height: 'auto' }} alt="" />
                        </div>
                        <div className="beneficios">
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <img src={destaque2} style={{ width: '50px' }} alt="" />
                                <span>Funcionamento<br />24 Horas por dia</span>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <img src={destaque5} style={{ width: '50px' }} alt="" />
                                <span>Segurança via API</span>
                            </div>
                        </div>
                    </div>

                    <div>
                        {!success && <h1>Sua playlists <br />online sempre</h1>}
                        {!success && <p>Insira seus dados abaixo e crie sua conta.</p>}

                        {success && <h1>Recebemos<br />sua solicitação</h1>}
                        {success && <p>Em breve nossa equipe entrará em contato com você.</p>}
                        {success && <p>Aguarde.</p>}

                        <br />
                        {!success && <form className='container' onSubmit={e => { e.preventDefault(); submit() }}>
                            <div className="row">
                                <div className="col-md-12">
                                    <label>Nome:</label>
                                    <input onChange={changeText} value={form.name} name='name' className='input_create_account' type="text" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <label>Email:</label>
                                    <input onChange={changeText} value={form.email} name='email' className='input_create_account' type="email" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Telefone:</label>
                                    <PhoneInput minLength={14} onChange={changeText} value={form.tel} name="tel" className='input_create_account' required />
                                </div>
                                <div className="col-md-6">
                                    <label>WhatsApp:</label>
                                    <PhoneInput minLength={14} onChange={changeText} value={form.whatsapp} name="whatsapp" className='input_create_account' required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <label>Onde Conheceu nossos serviços?</label>
                                    <select onChange={changeText} className='input_create_account' required name="aboutourservices">
                                        <option value="" selected></option>
                                        <option value="Google">Google</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="TikTok">TikTok</option>
                                        <option value="FaceBook">FaceBook</option>
                                        <option value="Indicação de um Amigo">Indicação de um Amigo</option>
                                        <option value="Equipe comercial">Equipe comercial</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <label>Quantas Playlists você tem?</label>
                                    <select onChange={changeText} className='input_create_account' required name="countplaylists">
                                        <option value="" selected></option>
                                        <option value="1 a 5 playlists">1 a 5 playlists</option>
                                        <option value="5 a 10 playlists">5 a 10 playlists</option>
                                        <option value="10 a 30 playlists">10 a 30 playlists</option>
                                        <option value="30 a 50 playlists">30 a 50 playlists</option>
                                        <option value="acima de 50 playlists">acima de 50 playlists</option>
                                        <option value="acima de 100 playlists">acima de 100 playlists</option>
                                        <option value="acima de 200 playlists">acima de 200 playlists</option>
                                    </select>
                                </div>
                            </div>
                            <div style={{ marginTop: 10, display: 'flex', justifyContent: 'end' }}>
                                <button className='button_create_accont'>CRIAR CONTA</button>
                            </div>
                        </form>}
                    </div>
                </div>
            </section>
            <br />
            <br />
            <section style={{ width: '100vw', height: 'auto' }}>
                <div className='robo_container_home'>
                    <div className="container">
                        <div className='robo_container_home' style={{ background: 'none' }}>
                            <div className='center_robo_text_home'>
                                <span style={{ color: '#fff', fontSize: 25, fontWeight: 'bold' }}>Inteligência<br />Artificial Online</span>
                                <br />
                                <span style={{ color: '#fff', fontSize: 20 }}>
                                    Nossa inteligência artificial protege sua<br />playlist contra ataques maliciosos e mantém<br />um relatório atualizado diariamente.
                                </span>
                            </div>
                            <div className='robot_img'>
                                <img src={robo} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default CreateAccount;