import React, { useEffect } from 'react';
import { GetAuth } from '../../api/Api';
import Imagem from "../../../images/pexels.jpg";
import { BsSpotify } from 'react-icons/bs';
import { api } from '../../api/net';

function CadastroNovoUsuario() {

    useEffect(() => {
        var params = new URLSearchParams(document.location.search)
        var token = params.get("token");
        var user_id = params.get("usr");
        if (!token || !user_id) window.location.href = '/';
        window.localStorage.setItem("id_register", user_id)
        api.get(`/token/validar?q=${token}`, res => {
            if (!res.data.valido) {
                window.location.href = '/';
            }
        }, err => {
            window.location.href = '/';
        });

    })

    return (
        <section style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <div className="container-fluid h-custom">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col-md-9 col-lg-6 col-xl-5">
                        <img src={Imagem}
                            className="img-fluid" alt="Sample image" />
                    </div>
                    <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                        <div>
                            <div>
                                <h1>Entre agora manter suas playlists a salva!</h1>
                                <p>É um convite irresistível que ressalta a importância da preservação de suas
                                    seleções musicais favoritas.
                                </p>
                            </div>
                            <div >
                                <button className='btn btn-primary' style={{ width: '100%' }} onClick={() => { GetAuth() }} type="button"><BsSpotify size={25} color='#fff' />Entrar com Spotify</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CadastroNovoUsuario;