import React, { useEffect, useState } from 'react';


export default ({ ranking }) => {

    const [count, setCount] = useState([]);

    useEffect(() => {
        if (ranking.length < 16) {
            let array = [];
            for (let i = 0; i < (16 - ranking.length); i++) {
                array.push(i);
            }
            setCount(array)
        }else{
            setCount([])
        }
    }, [])

    return (
        <section className='container-table'>
            <div className='header_table_ranking'>
                <h3 style={{ textAlign: 'center' }}>RANKING DE MAIORES ROLLBACKS</h3>
            </div>
            <table className='table_ranking'>
                <thead>
                    <tr>
                        <th className='position_ranking'>Posição</th>
                        <th className='playlist_ranking'>Nome da Playlist</th>
                        <th className='count_rollback'>Quantidade</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        ranking.map((x, i) => {
                            return <tr>
                                <td>{i + 1}°</td>
                                <td>{x.name}</td>
                                <td>{x.count}</td>
                            </tr>
                        })
                    }
                    {
                        count.map(() => {
                            return <tr>
                                <td style={{textAlign:'center'}}>-</td>
                                <td style={{textAlign:'center'}}>-</td>
                                <td style={{textAlign:'center'}}>-</td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </section>
    );
}