import React, { useEffect, useState } from 'react';
import Net, { api } from '../../api/net';
import { GetPlayList, UpdateImage, UpdatePlayList, RefrashToken } from '../../api/Api';
import { imageToBase64 } from "../../util";

import { ShowLoader, HiddenLoader } from "../../loader/loader";

const Robot = () => {
    const [finalizado, setFinalizado] = useState(false);

    const SalvarPlayLists = async () => {
        api.get("/playlists", async res => {
            if (res.data.length > 0) {
                for (let i = 0; i < res.data.length; i++) {

                    const api_access_token = window.localStorage.getItem("x-access-token");

                    window.localStorage.clear();
                    const user = res.data[i];
                    const { Name, Spotify_id, Access_token, Refresh_token, PlayLists } = user;

                    window.localStorage.setItem("x-access-token", api_access_token);
                    window.localStorage.setItem("access_token", Access_token);
                    window.localStorage.setItem("refresh_token", Refresh_token);
                    window.localStorage.setItem("user", JSON.stringify({
                        id: Spotify_id,
                        Name
                    }));
                    const { access_token } = await RefrashToken();
                    await GetPlayList(access_token);

                    const current_playlists = JSON.parse(window.localStorage.getItem("playlists"));
                    if (current_playlists != null) {
                        const bd_playlists = JSON.parse(PlayLists);

                        ValidaPlayLists(current_playlists.items, bd_playlists.items);
                    }
                }
                setFinalizado(true);
            }
        });
    }

    const ValidaPlayLists = async (current_playlists, db_playlists) => {
        db_playlists.forEach(async db_playlist => {
            let playlist = current_playlists.filter(playlist => playlist.id === db_playlist.id)[0];

            const { id, name, description } = playlist;

            const valido =
                db_playlist.name.toUpperCase() === name.toUpperCase()
                &&
                db_playlist.description.toUpperCase() === description.toUpperCase();

            var teveajustes = false;

            if (!valido) {
                const { access_token } = await RefrashToken();
                await UpdatePlayList(playlist, db_playlist, access_token)
                teveajustes = true;
            }

            if (playlist.images.length < 1 || playlist.images[0].url != db_playlist.images[0].url) {
                imageToBase64(db_playlist.images[0].url).then(async x => {
                    await UpdateImage(db_playlist, x.split(',')[1]);
                });
                teveajustes = true;
            }
            
            if (teveajustes) {
                api.post("log", {
                    user_id: JSON.parse(window.localStorage.getItem("user")).id,
                    playlist_id: db_playlist.id,
                    playlist_nome: db_playlist.name
                });
            }

        });
    }

    useEffect(() => {
        SalvarPlayLists()
    }, []);

    return <div>
        <div style={{ marginTop: 100 }}>
            <h1 name="text_status" style={{ textAlign: 'center' }}>{finalizado ? 'Processo finalizado com sucesso!' : 'Processando...'}</h1>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img src={require(finalizado ? "../../../images/process_accepting_2340.png" : "../../../images/gears-animation-13-3.gif")} alt="" />
            </div>
        </div>
    </div>
}

const Login = ({ setLoged }) => {
    const [erro, setErro] = useState('');
    const [usuario, setUsuario] = useState('');
    const [senha, setSenha] = useState('');
    const entrar = () => {
        setErro('');
        ShowLoader();
        api.get(`/usuario?email=${usuario}&senha=${senha}`, res => {
            if (res.data.logado) {
                window.localStorage.setItem("x-access-token", res.data["x-access-token"])
                api.updateAccessToken(res.data["x-access-token"]);
                setLoged(true);
            } else {
                setErro(res.data.erro)
            }
        }, err => {
            setErro(err.response ? err.response.data.erro : err.mensagem);
        });
        HiddenLoader();
    }

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100vh'
        }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img style={{ width: '250px', height: '150px', position: 'absolute', top: '4.5rem' }} src={require("../../../images/robo.png")} alt="" />
            </div>
            <form class="form-signin" style={{ borderRadius: 50, border: '1px solid #000', padding: 50 }}>
                <br />
                <br />
                <input onChange={e => setUsuario(e.target.value)} type="text" class="form-control" name="username" placeholder="Usuário" required="" autofocus="" />
                <br />
                <input onChange={e => setSenha(e.target.value)} type="password" class="form-control" name="password" placeholder="Password" required="" />
                <br />
                <button style={{ width: '100%' }} name='button' class="btn btn-lg btn-primary btn-block" type="button" onClick={() => entrar()} >Login</button>
            </form>
            <small style={{ color: 'red' }}>{erro}</small>
        </div>
    )
}

const Robo = () => {
    const [loged, setLoged] = useState(false);
    return loged ? <Robot /> : <Login setLoged={setLoged} />
}

export default Robo;