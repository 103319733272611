import React from 'react'
import { Component } from 'react'
import { Dropdown, Modal } from 'react-bootstrap'
import { api } from '../api/net'

export default class NewUserModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: true,
            email: '',
            senha: '',
            adm: '0'
        }
    }

    handleModal = () => {
        this.setState({ show: !this.state.show })
    }

    render() {

        const submit = () => {
            const { email, senha, adm } = this.state;
            api.post("usuario/novo", {
                email, senha, adm
            }, res => {
                if (res.data.cadastrado) {
                    alert('Usuario cadastrado com sucesso!');
                }else
                {
                    alert('Usuario não cadastrado :( ');
                }
            })
        }

        return (<>
            <Dropdown.Item onClick={this.handleModal}>Cria novo usuário</Dropdown.Item>

            <Modal show={this.state.show}>
                <Modal.Header>
                    Insira os dados do novo usuário
                </Modal.Header>

                <Modal.Body>
                    <form className='container' onSubmit={e => { e.preventDefault(); submit() }}>
                        <div className="row">
                            <div className="col-md-12">
                                <label>Email:</label>
                                <input onChange={e => this.setState({ email: e.target.value })} name='email' className='input_create_account' type="email" required />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <label>Senha temporária:</label>
                                <input onChange={e => this.setState({ senha: e.target.value })} name='psw' type="password" className='input_create_account' required />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <label>Usuário é adm?</label><small style={{ fontSize: 10 }}>Usuário adm tem acesso a cadastro de contas de novos usuários</small>
                                <select onChange={e => this.setState({ adm: e.target.value })} className='input_create_account' required name="aboutourservices">
                                    <option value="0" selected>Não</option>
                                    <option value="1">Sim</option>
                                </select>
                            </div>
                        </div>
                        <div style={{ marginTop: 10, display: 'flex', justifyContent: 'center' }}>
                            <button className='button_create_accont'>CRIAR CONTA</button>
                        </div>
                    </form>
                </Modal.Body>

                <Modal.Footer>
                    <button className='button_create_accont' onClick={() => this.handleModal()}>Fechar</button>
                </Modal.Footer>

            </Modal >
        </>);
    }
}