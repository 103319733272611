import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    },
};

function ChartLine(props) {
    const obj = [];
    const copyArray = props.data;
    copyArray.forEach((element, i) => {
        const exists = obj.filter(c => c.date == element.date).length > 0
        if (exists) {
            const current = copyArray[i].count;
            obj.filter(c => c.date == element.date)[0].count += current
        } else {
            obj.push({
                date: element.date,
                count: element.count,
            });
        }

    });

    let labels = obj.map(user=> user.date);

    const data = {
        labels,
        datasets: [
            {
                label: 'Margem de Rollbacks',
                data: obj.map((res) => res.count),
                borderColor: 'orange',
                backgroundColor: '#6CBD45',
            },
        ],
    };

    return <Line options={options} data={data} />;
}
export default ChartLine;