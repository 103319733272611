import axios from "axios";
import { ShowLoader, HiddenLoader } from '../loader/loader';

export default class Net {
    constructor(urlBase = "") {
        this.loginPage = "/Login";
        this.urlBase = urlBase;
        var token = window.localStorage.getItem("x-access-token") ? window.localStorage.getItem("x-access-token") : "x-access-token"
        this.api = axios.create({
            baseURL: urlBase,
            headers: {
                "x-access-token": token
            }
        });
    }

    execute = (api, funcResult, funcError) => {
        ShowLoader()
        api.then((response) => {
            if (funcResult != undefined) {
                funcResult(response);
                HiddenLoader()
            }
            HiddenLoader()
        })
            .catch((err) => {
                console.log({ error: err });
                if (err.response != null && err.response.status == 401) {
                    HiddenLoader()
                    window.location.href = this.loginPage;
                    return;
                }
                if (funcError != undefined) {
                    HiddenLoader()
                    funcError(err);
                }
            });
    }

    get = (url, funcResult, funcError) => {
        this.execute(this.api.get(url), funcResult, funcError);
    }

    getForm = (url, form, funcResult, funcError) => {
        if (url.indexOf('?') <= 0) url += "?";
        var first = true;
        for (var c in form) {
            if (!first) url += "&";
            url += c + "=" + form[c];
            first = false;
        }

        this.execute(this.api.get(url), funcResult, funcError);
    }

    delete = (url, funcResult, funcError) => {
        this.execute(this.api.delete(url), funcResult, funcError);
    }

    post = (url, form, funcResult, funcError) => {
        this.execute(this.api.post(url, form), funcResult, funcError);
    }

    put = (url, form, funcResult, funcError) => {
        this.execute(this.api.put(url, form), funcResult, funcError);
    }

    uploadFileForm(endpoint, funcResult, funcError) {
        var input = document.querySelector('input[type="file"]')

        var data = new FormData()
        for (const file of input.files) {
            data.append('files', file, file.name)
        }

        this.post(endpoint, data, funcResult, funcError)
    }

    updateAccessToken(access_token) {
        this.api = axios.create({
            baseURL: this.urlBase,
            headers: {
                "x-access-token": access_token
            }
        });
    }

}
const uri = "https://myplaylist.online" ;
// const uri = "http://localhost:8800" ;
export const api = new Net(`${uri}`);