import React, { useState } from 'react';
import { Form, FloatingLabel, Container } from 'react-bootstrap';
import { Layout } from '../../layout/layout_page';
import { api } from '../../api/net';
import './login.css'
import logo from '../../../images/ComLogoBaixo.png'
import login_img_com from '../../../images/ComLogo.png'
import android from '../../../images/Arquivos_Landing_Page/android.png'

function Login() {
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [erro, setErro] = useState('');

    const entrar = () => {
        setErro('');
        api.get(`/usuario?email=${email}&senha=${senha}`, res => {
            if (res.data.logado) {
                window.localStorage.setItem("x-access-token", res.data["x-access-token"])
                window.localStorage.setItem("_timer", "2000")
                window.location.href = "/Gerenciar";
            } else {
                if (res.data.erro) {
                    alert(res.data.erro)
                } else {
                    alert(res.message)
                }
            }
        }, err => {
            if (err.response) {
                setErro(err.response.data.erro);
            }
            else {
                setErro(err)
            }
        });
    }
    return (
        <Layout showFooter={false}>
            <br />
            <div className='container_login'>
                <div className='login_img'>
                    <img className='login_img_logo logoweb' src={login_img_com} alt="" />
                    <img src={logo} className='login_img_logo logomobile' />
                </div>
                <div className='form-login'>
                    <br />
                    <form onSubmit={e => { e.preventDefault(); entrar() }} autoComplete='false'>
                        <Container>
                            <div className='align-item-center'>
                                <img src={android} alt="" />
                            </div>
                            <h1>Acesse sua conta</h1>
                            <FloatingLabel controlId="floatingInputGrid" label="Email">
                                <Form.Control required onChange={e => setEmail(e.target.value)} type="email" placeholder="exemplo@example.com" />
                            </FloatingLabel>
                            <br />
                            <Form.Floating>
                                <Form.Control
                                    onChange={e => setSenha(e.target.value)}
                                    id="floatingPasswordCustom"
                                    type="password"
                                    placeholder="Password"
                                    required
                                />
                                <label htmlFor="floatingPasswordCustom">Password</label>
                            </Form.Floating>
                            <div className='align-item-end'>
                                <a href="/EsqueciSenha" className='forgetpassword'>Esqueceu a senha?</a>
                            </div>
                            <br />
                            <button className='btn-login'>FAZER LOGIN</button>
                        </Container>
                        <div className='footer-form-login'>
                            <div>
                                Ainda não tem conta?  <a href='/CriarConta'>Crie Sua Conta</a>
                            </div>
                            <small style={{ fontSize: '0.7rem' }}> &copy; MyPlaylist.online</small>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export { Login };