import React from 'react';

import { createBrowserRouter } from 'react-router-dom';
import CadastroNovoUsuario from '../views/registerPlayList/CadastroNovoUsuario';
import Agradecimento from '../views/registerPlayList/Agradecimento';
import Robot from '../views/robot/Robot';
import GerenciarUser from "../views/manageUser/GerenciarUser";
import Layout from '../layout/layout';
import EditUser from '../views/editUser/EditUser';
import { Home } from '../views/home/Home';
import { Login } from '../views/login/Login'
import CreateAccount from '../views/createaccount/CreateAccount';
import DashBoard from '../views/dashBoard';

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/Login",
        element: <Login />,
    },
    {
        path: "/robo",
        element: <Robot />,
    },
    {
        path: "/CadastroNovoUsuario",
        element: <CadastroNovoUsuario />,
    },
    {
        path: "/Gerenciar",
        element: <Layout><GerenciarUser /></Layout>,
    },
    {
        path: "/Agradecimento",
        element: <Agradecimento />
    },
    {
        path: "/EditUser",
        element: <Layout><EditUser /></Layout>
    },
    {
        path: "/CriarConta",
        element: <CreateAccount />
    },
    {
        path: "/DashBoard",
        element: <DashBoard />
    },
]);