const CLIENT_ID = "8513c0581a964dff8c90f274a663964f";
const CLIENT_SECRET = "bb00fa390e5148c1842358cf93cc546e";

const EDPOINTS = {
    API_TOKEN: "https://accounts.spotify.com/api/token",
    AUTHORIZEURL: "https://accounts.spotify.com/authorize",
    PLAYLISTS: "https://api.spotify.com/v1/users/",
    GET_USER: "https://api.spotify.com/v1/me",
    UPDATE_PLAY_LIST: "https://api.spotify.com/v1/playlists/"
}

export { CLIENT_ID, CLIENT_SECRET, EDPOINTS };