import React, { useEffect, useState } from 'react'

import Layout from '../../layout/layout';
import HeaderDash from './HeaderDash';
import RankingRollback from './RankingRollback';
import Charts from './Charts';

import { api } from '../../api/net';

function DashBoard() {
    const [countUsers, setCountUsers] = useState(0);
    const [countPlaylists, setCountPlaylists] = useState(0);
    const [countRollbacks, setCountRollbacks] = useState(0);
    const [data, setData] = useState([]);
    const [ranking, setRanking] = useState([]);
    const [initialDate, setInitialDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const GetCountPlayLists = (data) => {
        var count = 0;
        data.forEach(users => {
            const { PlayLists } = users;
            count += JSON.parse(PlayLists).items.length;
        });
        return count;
    }

    function formatarData(data) {
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // meses são zero-indexed
        const ano = data.getFullYear().toString();

        return `${ano}-${mes}-${dia}`;
    }

    const GetFirstLastDayOfMonth = () => {
        const hoje = new Date();
        const primeiroDia = new Date(hoje.getFullYear(), hoje.getMonth(), 1);
        const ultimoDia = new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0);

        const primeiroDiaFormatado = formatarData(primeiroDia);
        const ultimoDiaFormatado = formatarData(ultimoDia);

        return {
            primeiroDia: primeiroDiaFormatado,
            ultimoDia: ultimoDiaFormatado
        };
    }

    const GetCountRollbacks = (data) => {
        var count = 0;
        data.forEach(users => {
            count += users.LogCount;
        });
        return count;
    }

    const GetRanking = (array) => {
        const obj = [];
        
        array.forEach((element) => {
            const existsIndex = obj.findIndex((item) => item.name === element.name);
    
            if (existsIndex !== -1) {
                obj[existsIndex].count += element.count;
            } else {
                obj.push({
                    name: element.name,
                    count: element.count,
                    date: element.date,
                });
            }
        });
    
        obj.sort((a, b) => b.count - a.count);
    
        return obj;
    };
    const GetUsers = () => {
        api.get("usuario/todos?user_id=", res => {
            setCountRollbacks(GetCountRollbacks(res.data));
            setCountPlaylists(GetCountPlayLists(res.data));
            setCountUsers(res.data.length);
        })

        const date = GetFirstLastDayOfMonth();

        GetPlayLists(date.primeiroDia, date.ultimoDia);
    }

    const GetPlayLists = (startDate, endDate) => {
        api.get(`log/todos?user_id=&primeirodia=${startDate}&ultimodia=${endDate}`, res => {
            const obj = [];
            res.data.forEach(element => {
                const data = new Date(element.date);
                const dateFormated = `${String(data.getDate()).padStart(2, '0')}/${String(data.getMonth() + 1).padStart(2, '0')}/${data.getFullYear()}`;
                obj.push({
                    count: element.count,
                    date: dateFormated,
                    name: element.name
                })
            });
            const groupedByDate = obj.reduce((acc, item) => {
                const key = item.date;

                if (!acc[key]) {
                    acc[key] = [];
                }

                acc[key].push({ count: item.count, name: item.name });

                return acc;
            }, {});

            const resultArray = Object.keys(groupedByDate).map(key => {
                return {
                    date: key,
                    children: groupedByDate[key],
                }
            });
            const result = [];
            resultArray.forEach(element => {
                const { children, date } = element;
                for (let i = 0; i < children.length; i++) {
                    const obj = children[i];

                    result.push({
                        name: obj.name,
                        count: obj.count,
                        date: date
                    });
                }
            });


            let countRollback = 0;
            result.forEach(element => {
                countRollback += element.count
            });

            const newObj = [];
            const copyArray = result;
            copyArray.forEach((element, i) => {
                const exists = newObj.filter(c => c.name == element.name).length > 0
                if (exists) {
                    const current = copyArray[i].count;
                    newObj.filter(c => c.name == element.name)[0].count += current
                } else {
                    newObj.push({
                        name: element.name,
                        count: element.count,
                    });
                }

            });

            let labels = newObj.map(user => user.name);
            setCountPlaylists(labels.length);
            setCountRollbacks(countRollback)
            setData(result);
            setRanking(GetRanking(result))
        })
    }

    const GetByFilter = () => {
        GetPlayLists(initialDate, endDate)
    }

    useEffect(() => {
        GetUsers();
    }, [])

    return (
        <Layout>
            <main className='main-container'>
                <HeaderDash
                    countUser={countUsers}
                    countPlaylist={countPlaylists}
                    countRollback={countRollbacks}
                />
                <div className='container-charts'>
                    <Charts data={data}
                        setInitialDate={setInitialDate}
                        setEndDate={setEndDate}
                        GetByFilter={GetByFilter}
                    />
                    <RankingRollback ranking={ranking} />
                </div>
            </main>
        </Layout>
    )
}

export default DashBoard