import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import ConfettiExplosion from 'react-confetti-explosion';
import { ShowLoader } from "../../loader/loader";
import { Active, HiddenLoader } from "../../loader/loader";
import { GetTokenUser, GetUser, RefrashToken, GetPlayList } from '../../api/Api';
import { api } from "../../api/net";

function Agradecimento() {
    const [show, setShow] = useState(false);
    const [showErro, setShowErro] = useState(false);
    const [erro, setErro] = useState('');

    const CadastrarNovoUsuario = () => {
        const code = window.localStorage.getItem("code");
        const api_access_token = window.localStorage.getItem("x-access-token");
        const id_register = window.localStorage.getItem("id_register")

        window.localStorage.clear()
        window.localStorage.setItem("code", code)
        window.localStorage.setItem("x-access-token", api_access_token);
        window.localStorage.setItem("id_register", id_register);

        if (show) return;
        if (Active()) {
            ShowLoader();
        }
        GetTokenUser().then(async token_user => {
            debugger
            if (!token_user.error) {
                window.localStorage.setItem("access_token", token_user.access_token);
                window.localStorage.setItem("refresh_token", token_user.refresh_token);
            }
            else {
                await RefrashToken();
            }

            let code = window.localStorage.getItem("code");
            let access_token = window.localStorage.getItem("access_token");
            let refresh_token = window.localStorage.getItem("refresh_token");

            await GetUser(access_token);
            var user = JSON.parse(window.localStorage.getItem("user"));

            var user_playlist = [];
            await GetPlayList(access_token);
            var playlists = JSON.parse(window.localStorage.getItem("playlists"));

            playlists.items.map(playist => {
                if (playist.owner.id === user.id) {
                    user_playlist.push(playist)
                }
            })

            playlists.items = user_playlist
            window.localStorage.setItem("playlists", JSON.stringify(playlists))

            const form = {
                nome: user.display_name,
                spotify_id: user.id,
                access_token: window.localStorage.getItem("access_token"),
                refresh_token,
                playlists: window.localStorage.getItem("playlists"),
                user_id: id_register
            }

            api.post("/usuario/cadastro", form, async res => {
                if (res.data.cadastrado) {
                    setShow(true);
                    HiddenLoader();
                }
            }, err => {
                if (err.response) {
                    alert("ATENÇÃO \n" + err.response.data.mensagem);
                    setErro(err.response.data.mensagem);
                } else {
                    setErro(err.messagem);
                }
                setShow(false);
                setShowErro(true)
                HiddenLoader();
            })
        });
    }

    useEffect(() => {
        ShowLoader();

        var params = new URLSearchParams(document.location.search)
        var code = params.get("code");
        if (!code) window.location.href = '/';
        window.localStorage.setItem("code", code);

        CadastrarNovoUsuario();

    }, []);

    return (
        <Container>
            {show && <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <ConfettiExplosion
                    force={0.8}
                    duration={3000}
                    particleCount={250}
                    width={window.screen.width}
                    height={window.screen.height}
                />
                <h1>Cadastro realizado com sucesso!</h1>
                <h3>Bem-Vindo!!</h3>
            </div>}
            {showErro && <h1 style={{ textAlign: "center" }}>{erro}</h1>}
        </Container>
    );
}

export default Agradecimento;