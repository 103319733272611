import React, { useState } from 'react';
import { AiOutlineMenu } from 'react-icons/ai';
import { RxExit } from 'react-icons/rx';
import { FaInstagram, FaYoutube } from "react-icons/fa";

import logo_branco from '../../images/Arquivos_Landing_Page/logo_branca.svg'
import logo from '../../images/Arquivos_Landing_Page/logo.svg'


function Layout({ children, showFooter = true }) {
    const [showMenu, setShowMenu] = useState(false);

    const scrollToApp = () => {
        var app = document.getElementById("app");
        if (!app) {
            window.location.href = "/?app=true";
            setTimeout(() => {
                scrollToApp();
            }, 500);
        }else
        {
            app.scrollIntoView({
                behavior: "smooth",
                block: "nearest"
            });
        }
    }

    return (
        <section>
            <section style={{ width: '100vw', height: 'auto' }}>
                <div className={`menu_home ${showMenu ? 'menuactive' : ''}`} >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div className='container_menu_active_home'>
                            <RxExit size={40} color='#000' onClick={() => setShowMenu(false)} />
                        </div>
                        <hr />
                        <div className="container_links_menu_home">
                            <a href="/">INÍCIO</a>
                            <a onClick={scrollToApp} >APP</a>
                            <a href="/Login">LOGIN</a>
                            <a href="/CriarConta">CRIAR CONTA</a>
                        </div>
                        <hr />
                        <div style={{ padding: 10 }}>
                            <img src={logo} alt="" />
                        </div>
                    </div>
                </div>
                <div className='header_home'>
                    <div className="container">
                        <div onClick={() => window.location.href = "/"} style={{ cursor: 'pointer' }} className="container_img_home">
                            <img src={logo} alt="" />
                        </div>
                    </div>
                    <div className="container">
                        <div className='container_menu_home'>
                            <AiOutlineMenu size={25} onClick={() => setShowMenu(true)} />
                        </div>
                        <div className="container_links_home">
                            <a href="/">INÍCIO</a>
                            <a onClick={scrollToApp}>APP</a>
                            <a href="/Login">LOGIN</a>
                            <a href="/CriarConta">CRIAR CONTA</a>
                        </div>
                    </div>
                </div>
                {children}
                {showFooter && <br />}
                <footer className='footer_home' style={{ display: showFooter ? 'block' : 'none' }}>
                    <div className='container'>
                        <div className="row">
                            <div className="col-md-4" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <img onClick={() => window.location.href = "/"} style={{ width: '200px', cursor: 'pointer' }} src={logo_branco} alt="" />
                            </div>
                            <div className="col-md-4" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <a style={{ color: '#fff', textDecoration: 'none', cursor: 'pointer' }} href="https://www.instagram.com/myplaylistonline" target='_blank'><FaInstagram fontSize={25} /></a>
                                <a style={{ color: '#fff', textDecoration: 'none', cursor: 'pointer',marginLeft:10 }} href="#" target='_blank'><FaYoutube fontSize={25}/></a>
                            </div>
                            <div className="col-md-4" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                <span style={{ color: '#fff' }}>© MyPlaylist.Online</span>
                            </div>
                        </div>
                    </div>
                </footer>
            </section>
        </section>
    );
}

export { Layout };