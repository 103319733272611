import React, { useEffect, useState, useRef } from 'react';
import { BsFillTrashFill, BsFillPersonPlusFill } from 'react-icons/bs';
import { api } from '../../api/net';
import LinkCadastroModal from '../../modal/linkCadastroModal';
import EditPlaylistModal from '../../modal/EditPlaylistModal';
import { Accordion, Container } from 'react-bootstrap';
import { AiFillEdit } from 'react-icons/ai';
import { BiSolidPlaylist } from 'react-icons/bi'

const GerenciarUser = () => {
    const [usuarios, setUsuarios] = useState([]);
    const modalLink = useRef(null);
    const modalEdit = useRef(null);
    const [teste, setTeste] = useState(false);

    const buscarCadastros = () => {

        api.get("usuario/todos?user_id=", res => {
            setUsuarios(res.data);
        })
    }

    const deletaUsuario = (item) => {
        if (window.confirm(`Deseja realmente excluir o usuário ${item.Name}?`)) {
            api.delete(`usuario/delete?id=${item.Id}`);
            alert(`Usuário ${item.Name} deletado com sucesso!`)
            buscarCadastros();
        }
    }

    const AbrirModal = () => {
        if (window.confirm("Deseja cadastrar um novo usuário?")) {
            modalLink.current.Exibir();
        }
    }

    useEffect(buscarCadastros, []);

    return (
        <div>
            <EditPlaylistModal ref={modalEdit} />
            <LinkCadastroModal ref={modalLink} />
            <Container>
                <table className='table'>
                    <tbody>
                        {
                            usuarios.map(item => {
                                var obj = JSON.parse(item.PlayLists);
                                return (
                                    <tr>
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header style={{ width: '100%' }}>
                                                    <div style={{ width: '100%', justifyContent: 'space-around', alignItems: 'center', display: 'flex' }}>
                                                        <span>{item.Name} </span>
                                                        <span>Quantidade de PlayLists: {obj.items.length} | Quantidade de rollback: {item.LogCount}</span>
                                                        <button type="button" onClick={() => deletaUsuario(item)} className='btn btn-danger'><BsFillTrashFill size={20} color='#fff' /></button>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <h1 style={{ fontSize: 20 }}>PlayLists:</h1>
                                                    <br />
                                                    {
                                                        obj.items.map(playlist => {
                                                            return (
                                                                <button style={{ width: '100%' }} type="button" onClick={() => {
                                                                    window.localStorage.setItem("playlist_edit",
                                                                        JSON.stringify({
                                                                            p: playlist,
                                                                            r: btoa(item.Refresh_token),
                                                                            u: item.Id,
                                                                            ap: obj
                                                                        })); window.location.href = '/EditUser'
                                                                }} class="btn btn-light">
                                                                    <BiSolidPlaylist size={25} />
                                                                    <div>
                                                                        <span>Editar playlist: </span>
                                                                        {playlist.name}
                                                                    </div>
                                                                </button >
                                                            )



                                                        })
                                                    }
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </tr>
                                )
                            })

                        }
                        {usuarios.length == 0 && <td>Não foi encontrado dados</td>}
                    </tbody>
                </table>
            </Container>
        </div >
    )
}
export default GerenciarUser;