import React from 'react'
import { Component } from 'react'
import { Dropdown, Modal } from 'react-bootstrap'
import { api } from '../api/net'

export default class ChangePasswordModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            oldPsw: '',
            newPsw: '',
            confirmPsw: ''
        }
    }

    render(props) {

        return (<>

            <Modal show={this.props.show}>
                <Modal.Header>
                    Insira a nova senha de usuário
                </Modal.Header>

                <Modal.Body>
                    <form className='container' onSubmit={e => { e.preventDefault(); this.props.submit(this.state.oldPsw,this.state.newPsw,this.state.confirmPsw) }}>
                        <div className="row">
                            <div className="col-md-12">
                                <label>Senha antiga:</label>
                                <input onChange={e => this.setState({ oldPsw: e.target.value })} name='email' className='input_create_account' type="password" required />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <label>Nova Senha:</label>
                                <input onChange={e => this.setState({ newPsw: e.target.value })} name='psw' type="password" className='input_create_account' required />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <label>Confirma Nova Senha:</label>
                                <input onChange={e => this.setState({ confirmPsw: e.target.value })} name='psw' type="password" className='input_create_account' required />
                            </div>
                        </div>
                        <div style={{ marginTop: 10, display: 'flex', justifyContent: 'center' }}>
                            <button className='button_create_accont'>ALTERAR SENHA</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal >
        </>);
    }
}