import React from 'react'
import { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { AiFillCopy } from "react-icons/ai"
import { api } from '../api/net'

export default class LinkCadastroModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            link: "Recipient's username"
        }

        this.Exibir = () => {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';

            for (let i = 0; i < 50; i++) {
                const randomIndex = Math.floor(Math.random() * characters.length);
                result += characters.charAt(randomIndex);
            }
            result = btoa(result);
            const form = {
                token: result
            }

            const code_obj = JSON.parse(atob(window.localStorage.getItem("x-access-token").split('.')[1]))

            const user_id = code_obj.userId
            
            api.post("token/novo", form, res => {
                this.setState({ show: true, link: window.location.protocol + "//" + window.location.host + "/CadastroNovoUsuario?token=" + result + "&usr=" + user_id })
            });
        }
    }


    handleModal() {
        this.setState({ show: !this.state.show })
    }

    copy = () => {
        const inputElement = document.createElement("input");
        inputElement.value = this.state.link;
        document.body.appendChild(inputElement);

        inputElement.select();

        document.execCommand("copy");

        document.body.removeChild(inputElement);

        document.getElementById("basic-addon2").innerHTML = "Copiado!";
    }

    render() {
        return (<>
            <Modal show={this.state.show}>
                <Modal.Header>
                    Copie o link
                </Modal.Header>

                <Modal.Body>
                    <div class="input-group mb-3">
                        <input value={this.state.link} type="text" id='link_cadastro' disabled class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                        <button class="input-group-text" onClick={this.copy} id="basic-addon2"><AiFillCopy color='#00' /></button>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => this.handleModal()}>Fechar</Button>
                </Modal.Footer>

            </Modal>
        </>);
    }
}