import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Container } from 'react-bootstrap';

import { ShowLoader, HiddenLoader } from '../../loader/loader';

function PlayList(props) {
    const [image, setImage] = useState('')
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        ShowLoader()

        var params = JSON.parse(window.localStorage.getItem("playlist_edit"));
        if (!params) window.location.href = '/Gerenciar';

        const playlist = params.p;

        setImage(playlist.images[0].url)
        setName(playlist.name)
        setDescription(playlist.description)

        HiddenLoader()
    }, [])

    const mudarImagem = (e) => {
        const file = document.getElementById('file').files[0];
        
        if (!ValidFile(file)) {
            return;
        }

        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImage(imageUrl)
        }
    }

    function ValidFile(file) {

        if (file.size > (256 * 1024)) {
            alert('Imagem maior que permitido \n tamanho maximo: 256 KB')
            return false;
        }

        const path = file.name.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
        if (path.toLowerCase() !== 'jpeg' && path.toLowerCase() !== 'jpg') {
            alert('Tipo de arquivo inválido \n arquivo permitido: JPEG OU JPG')
            return false
        }

        return true;
    }

    return (
        <Container>
            <br />
            <Row>
                <Col className='col-md-6'>
                    <label htmlFor="file">Nome da playlist</label>
                    <Form.Control value={name} name='name' required onChange={e => setName(e.target.value)} type="text" placeholder="Nome da playlist" />
                </Col>
                <Col className='col-md-6'>
                    <label htmlFor="file">Descrição da playlist</label>
                    <Form.Control value={description} name='description' required onChange={e => setDescription(e.target.value)} type="text" placeholder="Descrição da playlist" />
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <label htmlFor="file">Mudar Imagem (tamanho max: 256 kb)</label>
                    <input onChange={mudarImagem} type='file' id='file' name='file' className='form-control' />
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img id='image' src={image} width={450} height={250} />
                    </div>
                </Col>
                <Col md={12}>
                    <br />
                    <button style={{ width: '100%' }} className='btn btn-primary' onClick={() => props.Atualizar({ name, description, image })}>Atualizar</button>
                </Col>
            </Row>
        </Container>
    );
}

export default PlayList;